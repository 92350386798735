import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // À créer pour le style

function HomePage({ user }) {
  const features = [
    {
      title: 'Gestion des Produits',
      path: '/products',
      description: 'Ajoutez, modifiez et supprimez des produits dans votre catalogue.',
      icon: '📦'
    },
    {
      title: 'Gestion des Locations',
      path: '/rentals',
      description: 'Suivez et gérez toutes vos locations en cours.',
      icon: '📅'
    },
    // {
    //   title: 'Inscriptions',
    //   path: '/register',
    //   description: 'Gérez les inscriptions des nouveaux utilisateurs.',
    //   icon: '👥'
    // }
  ];

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Bienvenue,</h1>
        <p className="welcome-text">
          Voici votre tableau de bord de gestion. Sélectionnez une fonctionnalité ci-dessous pour commencer.
        </p>
      </header>

      <div className="features-grid">
        {features.map((feature, index) => (
          <Link to={feature.path} className="feature-card" key={index}>
            <div className="feature-icon">{feature.icon}</div>
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomePage;