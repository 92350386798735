// import React, { useState, useEffect } from 'react';

// // Fonction utilitaire pour générer une liste de tous les jours de l'année en cours
// function getDaysOfYear(year) {
//   const days = [];
//   const start = new Date(year, 0, 1);
//   const end = new Date(year, 11, 31);

//   for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
//     days.push(new Date(d));
//   }

//   return days;
// }

// // Convertit une date 'YYYY-MM-DD' en un objet Date
// function parseDate(str) {
//   const [y,m,d] = str.split('-');
//   return new Date(parseInt(y), parseInt(m)-1, parseInt(d));
// }

// function RentalsPage() {
//   const [rentals, setRentals] = useState([]);
//   const [busyDays, setBusyDays] = useState({}); // { '2023-05-10': [ {rental1}, {rental2} ], ... }
//   const [selectedDays, setSelectedDays] = useState([]); // ['YYYY-MM-DD', ...]

//   const year = new Date().getFullYear(); // Année courante
//   const days = getDaysOfYear(year); 

//   const fetchRentals = async() => {
//     const res = await fetch('https://backend.ouest-pro-event.fr/admin/rentals');
//     const data = await res.json();
//     setRentals(data);
//   };

//   useEffect(() => {
//     fetchRentals();
//   }, []);

//   useEffect(() => {
//     // Une fois rentals chargé, on calcule les jours occupés
//     const map = {};
//     rentals.forEach(r => {
//       const start = parseDate(r.start_date);
//       const end = parseDate(r.end_date);

//       for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
//         const y = d.getFullYear();
//         const m = (d.getMonth()+1).toString().padStart(2,'0');
//         const day = d.getDate().toString().padStart(2,'0');
//         const key = `${y}-${m}-${day}`;
//         if (!map[key]) {
//           map[key] = [];
//         }
//         map[key].push(r);
//       }
//     });
//     setBusyDays(map);
//   }, [rentals]);

//   const toggleDaySelection = (day) => {
//     const y = day.getFullYear();
//     const m = (day.getMonth()+1).toString().padStart(2,'0');
//     const d = day.getDate().toString().padStart(2,'0');
//     const key = `${y}-${m}-${d}`;

//     if (busyDays[key]) {
//       // Si le jour est déjà sélectionné, on le retire, sinon on l'ajoute
//       if (selectedDays.includes(key)) {
//         setSelectedDays(selectedDays.filter(k => k !== key));
//       } else {
//         setSelectedDays([...selectedDays, key]);
//       }
//     } else {
//       // Jour non occupé, pas d'action
//     }
//   };

//   // Calculer toutes les locations des jours sélectionnés
//   const selectedRentals = [];
//   const addedRentalIds = new Set();
//   selectedDays.forEach(dayKey => {
//     if (busyDays[dayKey]) {
//       busyDays[dayKey].forEach(r => {
//         if (!addedRentalIds.has(r.id)) {
//           selectedRentals.push(r);
//           addedRentalIds.add(r.id);
//         }
//       });
//     }
//   });

//   // On peut afficher le calendrier en 12 colonnes (une par mois)
//   const months = [];
//   for (let i=0; i<12; i++) {
//     months.push(days.filter(d=>d.getMonth()===i));
//   }

//   const resetSelection = () => {
//     setSelectedDays([]);
//   };


//   const handleValidate = async (rentalId) => {
//     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/validate`, {
//       method: 'PUT'
//     });
//     if (res.ok) {
//       alert("Location validée");
//       fetchRentals();
//     } else {
//       alert("Erreur lors de la validation");
//     }
//   };
  
//   const handleRefuse = async (rentalId) => {
//     const res = await fetch(`https://backend.ouest-pro-event.fr//admin/rentals/${rentalId}/refuse`, {
//       method: 'PUT'
//     });
//     if (res.ok) {
//       alert("Location refusée");
//       fetchRentals();
//     } else {
//       alert("Erreur lors du refus");
//     }
//   };
  
//   const handleDelete = async (rentalId) => {
//     if (!window.confirm("Voulez-vous vraiment supprimer cette location ?")) return;
//     const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}`, {
//       method: 'DELETE'
//     });
//     if (res.ok) {
//       alert("Location supprimée");
//       fetchRentals();
//     } else {
//       alert("Erreur lors de la suppression");
//     }
//   };
  

//   return (
//     <div className="rentals-page">
//       <h2>Commandes - Vue Calendrier</h2>
//       <p>Cliquer sur un jour rouge pour le sélectionner. Vous pouvez en sélectionner plusieurs. Cliquez à nouveau pour le désélectionner.</p>

//       {selectedDays.length > 0 && (
//         <button className="reset-btn" onClick={resetSelection}>Réinitialiser la sélection</button>
//       )}

//       <div className="calendar-container">
//         {months.map((mdays, monthIndex) => {
//           const monthName = new Date(year, monthIndex, 1).toLocaleString('default', {month:'long'});
//           return (
//             <div key={monthIndex} className="month-block">
//               <h3 className="month-title">{monthName}</h3>
//               <div className="days-grid">
//                 <div className="day-header">L</div>
//                 <div className="day-header">M</div>
//                 <div className="day-header">M</div>
//                 <div className="day-header">J</div>
//                 <div className="day-header">V</div>
//                 <div className="day-header">S</div>
//                 <div className="day-header">D</div>
//                 {mdays.map((day, i) => {
//                   const y = day.getFullYear();
//                   const m = (day.getMonth()+1).toString().padStart(2,'0');
//                   const d = day.getDate().toString().padStart(2,'0');
//                   const key = `${y}-${m}-${d}`;
//                   const isBusy = !!busyDays[key];
//                   const isSelected = selectedDays.includes(key);

//                   return (
//                     <div 
//                       key={i} 
//                       onClick={()=>toggleDaySelection(day)}
//                       className={`day-cell ${isBusy ? 'busy' : ''} ${isSelected ? 'selected' : ''}`}
//                       title={isBusy ? 'Commandes ce jour' : 'Aucune commande'}
//                     >
//                       {day.getDate()}
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           );
//         })}
//       </div>

//       {selectedDays.length > 0 && (
//         <div className="details-container">
//           <h3>Détails pour les jours sélectionnés</h3>
//           {selectedRentals.length === 0 && <p>Aucune commande pour les jours sélectionnés.</p>}
//           {selectedRentals.map(r => (
//   <div key={r.id} className="rental-details">
//     <p><strong>Commande #{r.id}</strong></p>
//     <p><span className="label">Client :</span> {r.first_name} {r.last_name} ({r.email})</p>
//     <p><span className="label">Dates :</span> Du {r.start_date} au {r.end_date}</p>
//     <p><span className="label">Total :</span> {r.total_price} €</p>
//     <p><span className="label">Status :</span> {r.status}</p>
//     <h4>Articles :</h4>
//     <ul>
//       {r.items.map((it, idx)=>(
//         <li key={idx}>
//           Produit {it.product_id} ({it.product_name}), 
//           Qté: {it.quantity}, 
//           Prix/jour: {it.daily_price}
//         </li>
//       ))}
//     </ul>
//     <div style={{marginTop:'1rem'}}>
//       <button onClick={() => handleValidate(r.id)}>Valider</button>
//       <button onClick={() => handleRefuse(r.id)} style={{marginLeft:'0.5rem'}}>Refuser</button>
//       <button onClick={() => handleDelete(r.id)} style={{marginLeft:'0.5rem', background:'red', color:'white'}}>Supprimer</button>
//     </div>
//   </div>
// ))}

//         </div>
//       )}

//       <style jsx>{`
//         .rentals-page {
//           font-family: Arial, sans-serif;
//           padding: 1rem;
//           color: #333;
//         }

//         h2 {
//           margin-bottom: 1rem;
//         }

//         .reset-btn {
//           background: #e67e22;
//           border: none;
//           padding: 0.5rem 1rem;
//           border-radius: 5px;
//           color: #fff;
//           cursor: pointer;
//           margin-bottom: 1rem;
//         }
//         .reset-btn:hover {
//           background: #d35400;
//         }

//         .calendar-container {
//           display: flex;
//           flex-wrap: wrap;
//           gap: 1rem;
//           margin-bottom: 2rem;
//         }

//         .month-block {
//           border: 1px solid #ccc;
//           border-radius: 5px;
//           background: #f9f9f9;
//           padding: 0.5rem;
//           flex: 1 1 200px;
//           max-width: 200px;
//         }

//         .month-title {
//           text-align: center;
//           font-size: 1rem;
//           margin: 0.5rem 0;
//           font-weight: bold;
//         }

//         .days-grid {
//           display: grid;
//           grid-template-columns: repeat(7, 1fr);
//           gap: 3px;
//           font-size: 0.8rem;
//         }

//         .day-header {
//           text-align: center;
//           font-weight: bold;
//           background: #eee;
//           padding: 2px;
//           border-radius: 3px;
//         }

//         .day-cell {
//           text-align: center;
//           padding: 4px 0;
//           border-radius: 3px;
//           cursor: pointer;
//           background: #fff;
//           transition: background 0.3s;
//         }

//         .day-cell:hover {
//           background: #eaeaea;
//         }

//         .day-cell.busy {
//           background: #e74c3c;
//           color: #fff;
//         }

//         .day-cell.selected {
//           outline: 2px solid #2980b9;
//           background: #c0392b;
//         }

//         .details-container {
//           background: #f1f1f1;
//           border: 1px solid #ccc;
//           border-radius: 5px;
//           padding: 1rem;
//         }

//         .rental-details {
//           border-bottom: 1px solid #ccc;
//           padding: 0.5rem 0;
//         }

//         .rental-details:last-child {
//           border-bottom: none;
//         }

//         .label {
//           font-weight: bold;
//         }

//       `}</style>
//     </div>
//   );
// }

// export default RentalsPage;













// import React, { useState, useEffect } from 'react';
// import './RentalsPage.css';

// function getDaysOfYear(year) {
//   const days = [];
//   const start = new Date(year, 0, 1);
//   const end = new Date(year, 11, 31);

//   for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
//     days.push(new Date(d));
//   }

//   return days;
// }

// function parseDate(str) {
//   const [y, m, d] = str.split('-');
//   return new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
// }

// function formatDate(date) {
//   const options = { day: 'numeric', month: 'long', year: 'numeric' };
//   return date.toLocaleDateString('fr-FR', options);
// }

// function RentalsPage() {
//   const [rentals, setRentals] = useState([]);
//   const [busyDays, setBusyDays] = useState({});
//   const [selectedDays, setSelectedDays] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const year = new Date().getFullYear();
//   const days = getDaysOfYear(year);

//   const fetchRentals = async () => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const res = await fetch('https://backend.ouest-pro-event.fr/admin/rentals');
//       if (!res.ok) throw new Error('Erreur lors du chargement des commandes');
//       const data = await res.json();
//       setRentals(data);
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchRentals();
//   }, []);

//   useEffect(() => {
//     const map = {};
//     rentals.forEach(r => {
//       const start = parseDate(r.start_date);
//       const end = parseDate(r.end_date);

//       for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
//         const y = d.getFullYear();
//         const m = (d.getMonth() + 1).toString().padStart(2, '0');
//         const day = d.getDate().toString().padStart(2, '0');
//         const key = `${y}-${m}-${day}`;
//         if (!map[key]) map[key] = [];
//         map[key].push(r);
//       }
//     });
//     setBusyDays(map);
//   }, [rentals]);

//   const toggleDaySelection = (day) => {
//     const y = day.getFullYear();
//     const m = (day.getMonth() + 1).toString().padStart(2, '0');
//     const d = day.getDate().toString().padStart(2, '0');
//     const key = `${y}-${m}-${d}`;

//     if (busyDays[key]) {
//       setSelectedDays(prev => 
//         prev.includes(key) ? prev.filter(k => k !== key) : [...prev, key]
//       );
//     }
//   };

//   const getSelectedRentals = () => {
//     const addedRentalIds = new Set();
//     const selected = [];
//     selectedDays.forEach(dayKey => {
//       if (busyDays[dayKey]) {
//         busyDays[dayKey].forEach(r => {
//           if (!addedRentalIds.has(r.id)) {
//             selected.push(r);
//             addedRentalIds.add(r.id);
//           }
//         });
//       }
//     });
//     return selected;
//   };

//   const handleAction = async (rentalId, action) => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/${action}`, {
//         method: 'PUT'
//       });
//       if (!res.ok) throw new Error(`Erreur lors de l'action ${action}`);
//       await fetchRentals();
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleDelete = async (rentalId) => {
//     if (!window.confirm("Voulez-vous vraiment supprimer cette commande ?")) return;
//     setIsLoading(true);
//     setError(null);
//     try {
//       const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}`, {
//         method: 'DELETE'
//       });
//       if (!res.ok) throw new Error("Erreur lors de la suppression");
//       await fetchRentals();
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const months = Array(12).fill().map((_, i) => days.filter(d => d.getMonth() === i));

//   const getStatusClass = (status) => {
//     if (!status) return ''; // Gestion du cas où status est undefined
    
//     switch (status.toLowerCase()) {
//       case 'pending': return 'status-pending';
//       case 'validated': return 'status-validated';
//       case 'refused': return 'status-refused';
//       default: return '';
//     }
//   };

//   return (
//     <div className="rentals-page">
//       <div className="page-header">
//         <h1>Gestion des Commandes</h1>
//         <p className="page-description">
//           Sélectionnez les dates en rouge pour voir les commandes correspondantes.
//         </p>
//         {selectedDays.length > 0 && (
//           <button className="reset-button" onClick={() => setSelectedDays([])}>
//             Réinitialiser la sélection
//           </button>
//         )}
//       </div>

//       {error && <div className="error-alert">{error}</div>}

//       {isLoading && <div className="loading-overlay">Chargement...</div>}

//       <div className="calendar-container">
//         {months.map((mdays, monthIndex) => (
//           <div key={monthIndex} className="month-block">
//             <h3 className="month-title">
//               {new Date(year, monthIndex, 1).toLocaleString('fr-FR', { month: 'long' })}
//             </h3>
//             <div className="days-grid">
//               {['L', 'M', 'M', 'J', 'V', 'S', 'D'].map(day => (
//                 <div key={day} className="day-header">{day}</div>
//               ))}
//               {mdays.map((day, i) => {
//                 const dateKey = day.toISOString().split('T')[0];
//                 const isBusy = !!busyDays[dateKey];
//                 const isSelected = selectedDays.includes(dateKey);

//                 return (
//                   <div
//                     key={i}
//                     className={`day-cell ${isBusy ? 'busy' : ''} ${isSelected ? 'selected' : ''}`}
//                     onClick={() => toggleDaySelection(day)}
//                     title={isBusy ? `${busyDays[dateKey].length} commande(s)` : 'Aucune commande'}
//                   >
//                     {day.getDate()}
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         ))}
//       </div>

//       {selectedDays.length > 0 && (
//         <div className="details-container">
//           <h2>Détails des commandes sélectionnées</h2>
//           {getSelectedRentals().map(rental => (
//             <div key={rental.id} className="rental-card">
//              <div className="rental-header">
//   <span className="rental-id">Commande #{rental.id}</span>
//   {rental.status && (
//     <span className={`rental-status ${getStatusClass(rental.status)}`}>
//       {rental.status}
//     </span>
//   )}
// </div>

//               <div className="rental-info">
//                 <div className="info-group">
//                   <span className="info-label">Client</span>
//                   <span>{rental.first_name} {rental.last_name}</span>
//                   <span>{rental.email}</span>
//                 </div>
//                 <div className="info-group">
//                   <span className="info-label">Période</span>
//                   <span>Du {formatDate(parseDate(rental.start_date))}</span>
//                   <span>au {formatDate(parseDate(rental.end_date))}</span>
//                 </div>
//                 <div className="info-group">
//                   <span className="info-label">Montant total</span>
//                   <span>{rental.total_price} €</span>
//                 </div>
//               </div>

//               <div className="items-list">
//                 <h4>Articles commandés</h4>
//                 {rental.items.map((item, idx) => (
//                   <div key={idx} className="item-row">
//                     <span>{item.product_name}</span>
//                     <span>{item.quantity} unité(s)</span>
//                     <span>{item.daily_price} €/jour</span>
//                   </div>
//                 ))}
//               </div>

//               <div className="action-buttons">
//                 <button 
//                   className="btn btn-validate"
//                   onClick={() => handleAction(rental.id, 'validate')}
//                 >
//                   Valider
//                 </button>
//                 <button 
//                   className="btn btn-refuse"
//                   onClick={() => handleAction(rental.id, 'refuse')}
//                 >
//                   Refuser
//                 </button>
//                 <button 
//                   className="btn btn-delete"
//                   onClick={() => handleDelete(rental.id)}
//                 >
//                   Supprimer
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// export default RentalsPage;





















import React, { useState, useEffect } from 'react';
import './RentalsPage.css';

function RentalsPage() {
  const [rentals, setRentals] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedRental, setSelectedRental] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }).format(date);
  };

  // Récupération des locations
  const fetchRentals = async () => {
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/rentals');
      const data = await res.json();
      console.log(data)
      setRentals(data);
      console.log(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des locations:", error);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  // Organiser les locations par produit
  const groupRentalsByProduct = () => {
    const groupedRentals = {};
    
    rentals.forEach(rental => {
      rental.items.forEach(item => {
        if (!groupedRentals[item.product_id]) {
          groupedRentals[item.product_id] = {
            productName: item.product_name,
            rentals: []
          };
        }
        groupedRentals[item.product_id].rentals.push({
          ...rental,
          quantity: item.quantity,
          dailyPrice: item.daily_price
        });
      });
    });
    
    return groupedRentals;
  };

  // Générer le calendrier du mois
  const getDaysInMonth = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    return Array.from({length: daysInMonth}, (_, i) => i + 1);
  };

  const handleStatusAction = async (rentalId, action) => {
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/rentals/${rentalId}/${action}`, {
        method: 'PUT'
      });
      if (res.ok) {
        fetchRentals();
      }
    } catch (error) {
      console.error(`Erreur lors de l'action ${action}:`, error);
    }
  };

  const monthNames = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  return (
    <div className="rentals-page">
      <div className="rentals-header">
        <h1>Gestion des Locations</h1>
        <div className="date-selector">
          <select 
            value={selectedMonth} 
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
          >
            {monthNames.map((month, index) => (
              <option key={index} value={index}>{month}</option>
            ))}
          </select>
          <select 
            value={selectedYear} 
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
          >
            {[2023, 2024, 2025].map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="products-timeline">
        {Object.entries(groupRentalsByProduct()).map(([productId, data]) => (
          <div key={productId} className="product-row">
            <div className="product-info">
              <h3>{data.productName}</h3>
            </div>
            {/* <div 
              className="timeline"
              style={{ gridTemplateColumns: `repeat(${getDaysInMonth().length}, 1fr)` }}
            >
              <div className="days-header">
                {getDaysInMonth().map(day => (
                  <div key={day} className="day-cell">{day}</div>
                ))}
              </div>
              <div className="rentals-timeline">
                {data.rentals
                  .filter(rental => {
                    const startDate = new Date(rental.start_date);
                    return startDate.getMonth() === selectedMonth && startDate.getFullYear() === selectedYear;
                  })
                  .map((rental, index) => {
                    const startDate = new Date(rental.start_date);
                    const endDate = new Date(rental.end_date);
                    
                    const startDay = startDate.getDate();
                    const endDay = endDate.getDate();
                    
                    // S'assurer que les dates ne dépassent pas le mois sélectionné
                    const clampedEndDay = endDay > getDaysInMonth().length ? getDaysInMonth().length : endDay;

                    return (
                      <div 
                        key={rental.id} 
                        className={`rental-bar status-${rental.status?.toLowerCase()}`}
                        style={{
                          gridColumn: `${startDay} / ${clampedEndDay + 1}`,
                          gridRow: index + 2, // Commence à la deuxième ligne pour laisser la première ligne pour les jours
                        }}
                        onClick={() => setSelectedRental(rental)}
                      >
                        {rental.first_name} {rental.last_name}
                      </div>
                    );
                  })}
              </div> */}

              
            {/* </div> */}







            <div className="timeline">
  <div 
    className="days-header" 
    style={{ gridTemplateColumns: `repeat(${getDaysInMonth().length}, 1fr)` }}
  >
    {getDaysInMonth().map(day => (
      <div key={day} className="day-cell">{day}</div>
    ))}
  </div>

  <div className="rentals-timeline">
  {data.rentals
    .filter(rental => {
      const startDate = new Date(rental.start_date);
      return startDate.getMonth() === selectedMonth && startDate.getFullYear() === selectedYear;
    })
    .map((rental) => {
      const startDate = new Date(rental.start_date);
      const endDate = new Date(rental.end_date);

      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const clampedEndDay = endDay > getDaysInMonth().length ? getDaysInMonth().length : endDay;

      return (
        <div 
          key={rental.id}
          className="rental-row"
          style={{ gridTemplateColumns: `repeat(${getDaysInMonth().length}, 1fr)` }}
        >
          <div 
            className={`rental-bar status-${rental.status?.toLowerCase()}`}
            style={{
              gridColumn: `${startDay} / ${clampedEndDay + 1}`
            }}
            onClick={() => setSelectedRental(rental)} // Réinsertion de l'événement onClick
          >
            {rental.first_name} {rental.last_name}
          </div>
        </div>
      );
    })}
</div>

</div>




          </div>
        ))}
      </div>

      {selectedRental && (
        <div className="modal-overlay" onClick={() => setSelectedRental(null)}>
          <div className="modal" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Détails de la location</h2>
              <button className="close-button" onClick={() => setSelectedRental(null)}>×</button>
            </div>
            
            <div className="modal-content">
              <div className="info-section">
                <div className="info-group">
                  <h3>Informations client</h3>
                  <div className="info-item">
                    <span className="label">Client:</span>
                    <span className="value">{selectedRental.first_name} {selectedRental.last_name}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Email:</span>
                    <span className="value">{selectedRental.email}</span>
                  </div>
                </div>

                <div className="info-group">
                  <h3>Détails de la location</h3>
                  <div className="info-item">
                    <span className="label">Période:</span>
                    <span className="value">Du {formatDate(selectedRental.start_date)} au {formatDate(selectedRental.end_date)}</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Quantité:</span>
                    <span className="value">{selectedRental.quantity}</span>
                  </div>
                </div>

                <div className="info-group">
                  <h3>Informations de paiement</h3>
                  <div className="info-item">
                    <span className="label">Prix journalier:</span>
                    <span className="value">{selectedRental.dailyPrice}€</span>
                  </div>
                  <div className="info-item">
                    <span className="label">Total:</span>
                    <span className="value">{selectedRental.total_price}€</span>
                  </div>
                </div>
                
                <div className="status-section">
                  <span className={`status-badge status-${selectedRental.status?.toLowerCase()}`}>
                    {selectedRental.status}
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button 
                className="action-button validate"
                onClick={() => handleStatusAction(selectedRental.id, 'validate')}
              >
                Valider
              </button>
              <button 
                className="action-button refuse"
                onClick={() => handleStatusAction(selectedRental.id, 'refuse')}
              >
                Refuser
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RentalsPage;


