import React, { useState, useEffect } from 'react';
import './ProductsPage.css';
function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);

  const [name, setName] = useState('');
  const [stock, setStock] = useState(0);
  const [dailyPrice, setDailyPrice] = useState(0);
  const [categoryPaths, setCategoryPaths] = useState([[""]]);

  const [selectedPhotos, setSelectedPhotos] = useState([]); // Photos sélectionnées pour téléchargement
  const [photoPreviews, setPhotoPreviews] = useState([]); // Prévisualisations des photos
  const [isLoading, setIsLoading] = useState(false); // Indicateur de chargement
  const [error, setError] = useState(null); // Message d'erreur

  // Fonction pour récupérer les produits depuis le backend
  const fetchProducts = async () => {
    try {
      const res = await fetch('https://backend.ouest-pro-event.fr/admin/products');
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des produits');
      }
      const data = await res.json();
      setProducts(data);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Gestion de la sélection des photos avec prévisualisation et validation
  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    // Validation des fichiers
    const validFiles = [];
    const previews = [];

    files.forEach(file => {
      if (!file.type.startsWith('image/')) {
        alert(`Le fichier ${file.name} n'est pas une image valide.`);
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // Limite de 5MB par image
        alert(`Le fichier ${file.name} dépasse la taille maximale de 5MB.`);
        return;
      }
      // Vérifier si le fichier est déjà sélectionné
      const isDuplicate = selectedPhotos.some(selectedFile => selectedFile.name === file.name && selectedFile.size === file.size);
      if (isDuplicate) {
        alert(`Le fichier ${file.name} est déjà sélectionné.`);
        return;
      }
      validFiles.push(file);
      previews.push(URL.createObjectURL(file));
    });

    setSelectedPhotos(prevPhotos => [...prevPhotos, ...validFiles]);
    setPhotoPreviews(prevPreviews => [...prevPreviews, ...previews]);
  };

  // Nettoyage des URL de prévisualisation pour éviter les fuites de mémoire
  useEffect(() => {
    return () => {
      photoPreviews.forEach(src => URL.revokeObjectURL(src));
    };
  }, [photoPreviews]);

  // Fonction pour créer ou mettre à jour un produit
  const handleCreate = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Préparation des données du produit
      const payload = {
        name,
        stock,
        dailyPrice,
        categoryPaths: categoryPaths
          .map(path => path.filter(cat => cat.trim() !== ""))
          .filter(path => path.length > 0)
      };

      const method = editingProduct ? 'PUT' : 'POST';
      const url = editingProduct
        ? `https://backend.ouest-pro-event.fr/admin/products/${editingProduct.id}`
        : `https://backend.ouest-pro-event.fr/admin/products`;

      // Envoi des données du produit
      const res = await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de l'opération sur le produit");
      }

      const resData = await res.json();
      const productId = editingProduct ? editingProduct.id : resData.id;

      // Si des photos sont sélectionnées, les télécharger
      if (selectedPhotos.length > 0) {
        const formData = new FormData();
        selectedPhotos.forEach(photo => formData.append('photos', photo));

        const photoRes = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${productId}/photos`, {
          method: 'POST',
          body: formData
        });

        if (!photoRes.ok) {
          const errData = await photoRes.json();
          throw new Error(errData.error || "Erreur lors du téléchargement des photos");
        }
      }

      alert(editingProduct ? "Produit mis à jour avec succès" : "Produit créé avec succès");
      // Réinitialiser les champs du formulaire
      setName('');
      setStock(0);
      setDailyPrice(0);
      setCategoryPaths([[""]]);
      setSelectedPhotos([]);
      setPhotoPreviews([]);
      setEditingProduct(null);
      fetchProducts();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour éditer un produit
  const handleEdit = async (product) => {
    setIsLoading(true);
    setError(null);
    try {
      console.log(`https://backend.ouest-pro-event.fr/admin/products/${product.id}`)
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${product.id}`);
      if (!res.ok) {
        throw new Error('Erreur lors de la récupération des détails du produit');
      }
      const detailedProduct = await res.json();
      setEditingProduct(detailedProduct);
      setName(detailedProduct.name);
      setStock(detailedProduct.stock);
      setDailyPrice(detailedProduct.dailyPrice);
      setCategoryPaths(detailedProduct.categoryPaths.length > 0 ? detailedProduct.categoryPaths : [[""]]);
      setPhotoPreviews([]); // Réinitialiser les prévisualisations
      setSelectedPhotos([]); // Réinitialiser les photos sélectionnées
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer un produit
  const handleDelete = async (productId) => {
    if (!window.confirm("Voulez-vous vraiment supprimer ce produit ?")) return;
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${productId}`, {
        method: 'DELETE'
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la suppression du produit");
      }
      alert("Produit supprimé avec succès");
      fetchProducts();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonctions pour gérer les chemins de catégories
  const handlePathChange = (pathIndex, levelIndex, value) => {
    const updated = [...categoryPaths];
    updated[pathIndex][levelIndex] = value;
    setCategoryPaths(updated);
  };

  const addNewPath = () => {
    setCategoryPaths([...categoryPaths, [""]]);
  };

  const addLevelToPath = (pathIndex) => {
    const updated = [...categoryPaths];
    updated[pathIndex].push("");
    setCategoryPaths(updated);
  };

  const removePath = (pathIndex) => {
    const updated = [...categoryPaths];
    updated.splice(pathIndex, 1);
    if (updated.length === 0) {
      updated.push([""]);
    }
    setCategoryPaths(updated);
  };

  const removeLevel = (pathIndex, levelIndex) => {
    const updated = [...categoryPaths];
    updated[pathIndex].splice(levelIndex, 1);
    if (updated[pathIndex].length === 0) {
      updated[pathIndex].push("");
    }
    setCategoryPaths(updated);
  };

  // Fonction pour supprimer une photo existante
  const handleDeletePhoto = async (photoId) => {
    if (!window.confirm("Voulez-vous vraiment supprimer cette photo ?")) return;
    setIsLoading(true);
    setError(null);
    try {
      const res = await fetch(`https://backend.ouest-pro-event.fr/admin/products/${editingProduct.id}/photos/${photoId}`, {
        method: 'DELETE'
      });
      if (!res.ok) {
        const errData = await res.json();
        throw new Error(errData.error || "Erreur lors de la suppression de la photo");
      }
      alert("Photo supprimée avec succès");
      // Rafraîchir les détails du produit
      handleEdit(editingProduct);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour supprimer une photo sélectionnée avant téléchargement
  const handleRemoveSelectedPhoto = (index) => {
    const updatedPhotos = [...selectedPhotos];
    const updatedPreviews = [...photoPreviews];
    updatedPhotos.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setSelectedPhotos(updatedPhotos);
    setPhotoPreviews(updatedPreviews);
  };

  return (
    // <div style={{ padding: '1rem', fontFamily: 'Arial, sans-serif' }}>
    //   <h2 style={{ marginBottom: '1rem' }}>Gestion des Produits</h2>

    //   {/* Affichage des messages d'erreur */}
    //   {error && (
    //     <div style={{
    //       background: '#f8d7da',
    //       color: '#721c24',
    //       padding: '0.75rem 1.25rem',
    //       border: '1px solid #f5c6cb',
    //       borderRadius: '0.25rem',
    //       marginBottom: '1rem'
    //     }}>
    //       {error}
    //     </div>
    //   )}

    //   <div style={{
    //     background: '#f9f9f9',
    //     border: '1px solid #ccc',
    //     borderRadius: '5px',
    //     padding: '1rem',
    //     marginBottom: '2rem',
    //     position: 'relative'
    //   }}>
    //     {/* Indicateur de chargement */}
    //     {isLoading && (
    //       <div style={{
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         right: 0,
    //         bottom: 0,
    //         background: 'rgba(255, 255, 255, 0.7)',
    //         display: 'flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         zIndex: 1,
    //         borderRadius: '5px'
    //       }}>
    //         <div className="loader"></div>
    //       </div>
    //     )}

    //     <h3>{editingProduct ? "Modifier le produit" : "Créer un produit"}</h3>
    //     <div style={{ marginBottom: '0.5rem' }}>
    //       <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0.2rem' }}>Nom :</label>
    //       <input
    //         placeholder="Nom du produit"
    //         value={name}
    //         onChange={e => setName(e.target.value)}
    //         style={{ width: '100%', padding: '0.3rem' }}
    //       />
    //     </div>
    //     <div style={{ marginBottom: '0.5rem' }}>
    //       <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0.2rem' }}>Stock :</label>
    //       <input
    //         placeholder="Stock disponible"
    //         type="number"
    //         value={stock}
    //         onChange={e => setStock(parseInt(e.target.value))}
    //         style={{ width: '100%', padding: '0.3rem' }}
    //       />
    //     </div>
    //     <div style={{ marginBottom: '0.5rem' }}>
    //       <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0.2rem' }}>Prix / jour :</label>
    //       <input
    //         placeholder="Prix par jour"
    //         type="number"
    //         value={dailyPrice}
    //         onChange={e => setDailyPrice(parseFloat(e.target.value))}
    //         style={{ width: '100%', padding: '0.3rem' }}
    //       />
    //     </div>

    //     <h4>Catégories et sous-catégories :</h4>
    //     {categoryPaths.map((path, pathIndex) => (
    //       <div key={pathIndex} style={{
    //         marginBottom: '0.5rem',
    //         border: '1px solid #ddd',
    //         padding: '0.5rem',
    //         borderRadius: '5px',
    //         background: '#fff'
    //       }}>
    //         <strong>Chemin {pathIndex + 1}</strong>
    //         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginTop: '0.5rem' }}>
    //           {path.map((level, levelIndex) => (
    //             <div key={levelIndex} style={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
    //               <input
    //                 type="text"
    //                 placeholder={`Niveau ${levelIndex + 1}`}
    //                 value={level}
    //                 onChange={e => handlePathChange(pathIndex, levelIndex, e.target.value)}
    //                 style={{ padding: '0.3rem' }}
    //               />
    //               {levelIndex > 0 && (
    //                 <button onClick={() => removeLevel(pathIndex, levelIndex)} style={{
    //                   background: '#e74c3c',
    //                   color: '#fff',
    //                   border: 'none',
    //                   padding: '0.3rem',
    //                   borderRadius: '3px',
    //                   cursor: 'pointer'
    //                 }}>X</button>
    //               )}
    //             </div>
    //           ))}
    //           <button onClick={() => addLevelToPath(pathIndex)} style={{
    //             background: '#3498db',
    //             color: '#fff',
    //             border: 'none',
    //             padding: '0.3rem 0.5rem',
    //             borderRadius: '3px',
    //             cursor: 'pointer'
    //           }}>+ Niveau</button>
    //         </div>
    //         {categoryPaths.length > 1 && (
    //           <button style={{
    //             marginTop: '0.5rem',
    //             background: '#e74c3c',
    //             color: '#fff',
    //             border: 'none',
    //             padding: '0.3rem 0.5rem',
    //             borderRadius: '3px',
    //             cursor: 'pointer'
    //           }}
    //             onClick={() => removePath(pathIndex)}>Supprimer ce chemin</button>
    //         )}
    //       </div>
    //     ))}
    //     <button onClick={addNewPath} style={{
    //       background: '#2ecc71',
    //       color: '#fff',
    //       border: 'none',
    //       padding: '0.5rem 1rem',
    //       borderRadius: '3px',
    //       cursor: 'pointer',
    //       marginTop: '0.5rem'
    //     }}>Ajouter un nouveau chemin</button>

    //     {/* Champ pour sélectionner les photos */}
    //     <div style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
    //       <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '0.2rem' }}>Photos :</label>
    //       <input
    //         type="file"
    //         multiple
    //         accept="image/*"
    //         onChange={handlePhotoChange}
    //         style={{ width: '100%', padding: '0.3rem' }}
    //       />
    //     </div>

    //     {/* Prévisualisation des photos sélectionnées */}
    //     {photoPreviews.length > 0 && (
    //       <div style={{ marginTop: '1rem' }}>
    //         <h4>Prévisualisation des photos sélectionnées :</h4>
    //         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
    //           {photoPreviews.map((src, index) => (
    //             <div key={index} style={{ position: 'relative' }}>
    //               <img
    //                 src={src}
    //                 alt={`Prévisualisation ${index + 1}`}
    //                 style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
    //               />
    //               <button
    //                 onClick={() => handleRemoveSelectedPhoto(index)}
    //                 style={{
    //                   position: 'absolute',
    //                   top: '5px',
    //                   right: '5px',
    //                   background: 'rgba(231, 76, 60, 0.8)',
    //                   color: '#fff',
    //                   border: 'none',
    //                   borderRadius: '50%',
    //                   width: '20px',
    //                   height: '20px',
    //                   cursor: 'pointer'
    //                 }}
    //               >×</button>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     )}

    //     {/* Affichage des photos existantes lors de l'édition */}
    //     {editingProduct && editingProduct.photos && editingProduct.photos.length > 0 && (
    //       <div style={{ marginTop: '1rem' }}>
    //         <h4>Photos Actuelles :</h4>
    //         <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
    //           {editingProduct.photos.map(photo => (
    //             <div key={photo.id} style={{ position: 'relative' }}>
    //               <img
    //                 src={`https://backend.ouest-pro-event.fr${photo.url}`}
    //                 alt={`Photo de ${editingProduct.name}`}
    //                 style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
    //               />
    //               <button
    //                 onClick={() => handleDeletePhoto(photo.id)}
    //                 style={{
    //                   position: 'absolute',
    //                   top: '5px',
    //                   right: '5px',
    //                   background: 'rgba(231, 76, 60, 0.8)',
    //                   color: '#fff',
    //                   border: 'none',
    //                   borderRadius: '50%',
    //                   width: '20px',
    //                   height: '20px',
    //                   cursor: 'pointer'
    //                 }}
    //               >×</button>
    //             </div>
    //           ))}
    //         </div>
    //       </div>
    //     )}

    //     <div style={{ marginTop: '1rem' }}>
    //       <button onClick={handleCreate} style={{
    //         background: '#27ae60',
    //         color: '#fff',
    //         border: 'none',
    //         padding: '0.7rem 1.2rem',
    //         borderRadius: '3px',
    //         cursor: 'pointer'
    //       }}>{editingProduct ? "Enregistrer les modifications" : "Créer"}</button>
    //       {editingProduct && (
    //         <button onClick={() => {
    //           setEditingProduct(null);
    //           setName('');
    //           setStock(0);
    //           setDailyPrice(0);
    //           setCategoryPaths([[""]]);
    //           setSelectedPhotos([]);
    //           setPhotoPreviews([]);
    //           setError(null);
    //         }} style={{
    //           marginLeft: '1rem',
    //           background: '#95a5a6',
    //           color: '#fff',
    //           border: 'none',
    //           padding: '0.7rem 1.2rem',
    //           borderRadius: '3px',
    //           cursor: 'pointer'
    //         }}>Annuler</button>
    //       )}
    //     </div>
    //   </div>

    //   <h3>Liste des produits</h3>
    //   <ul style={{ listStyleType: 'none', padding: 0 }}>
    //     {products.map(p => (
    //       <li key={p.id} style={{ borderBottom: '1px solid #ccc', padding: '0.5rem 0' }}>
    //         <strong>{p.name}</strong> - Stock: {p.stock} - {p.dailyPrice} €/jour
    //         {p.categoryPaths && p.categoryPaths.length > 0 && (
    //           <ul style={{ listStyleType: 'disc', marginLeft: '1.5rem' }}>
    //             {p.categoryPaths.map((path, idx) => (
    //               <li key={idx}>{path.join(" > ")}</li>
    //             ))}
    //           </ul>
    //         )}
    //         {/* Affichage des photos */}
    //         {p.photos && p.photos.length > 0 && (
    //           <div style={{ marginTop: '0.5rem', display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
    //             {p.photos.map(photo => (
    //               <img
    //                 key={photo.id}
    //                 src={`https://backend.ouest-pro-event.fr${photo.url}`}
    //                 alt={`Photo de ${p.name}`}
    //                 style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
    //               />
    //             ))}
    //           </div>
    //         )}
    //         <div style={{ marginTop: '0.5rem' }}>
    //           <button onClick={() => handleEdit(p)} style={{
    //             marginRight: '0.5rem',
    //             background: '#3498db',
    //             color: '#fff',
    //             border: 'none',
    //             padding: '0.3rem 0.5rem',
    //             borderRadius: '3px',
    //             cursor: 'pointer'
    //           }}>Modifier</button>
    //           <button onClick={() => handleDelete(p.id)} style={{
    //             background: '#e74c3c',
    //             color: '#fff',
    //             border: 'none',
    //             padding: '0.3rem 0.5rem',
    //             borderRadius: '3px',
    //             cursor: 'pointer'
    //           }}>Supprimer</button>
    //         </div>
    //       </li>
    //     ))}
    //   </ul>

    //   {/* Style pour l'indicateur de chargement */}
    //   <style>
    //     {`
    //       .loader {
    //         border: 8px solid #f3f3f3;
    //         border-top: 8px solid #3498db;
    //         border-radius: 50%;
    //         width: 60px;
    //         height: 60px;
    //         animation: spin 1s linear infinite;
    //       }

    //       @keyframes spin {
    //         0% { transform: rotate(0deg); }
    //         100% { transform: rotate(360deg); }
    //       }
    //     `}
    //   </style>
    // </div>












    <div className="products-page">
      <header className="page-header">
        <h1>Gestion des Produits</h1>
        {error && <div className="error-alert">{error}</div>}
      </header>

      <div className="main-content">
        <section className="product-form-section">
          <div className="card">
            <div className="card-header">
              <h2>{editingProduct ? "Modifier le produit" : "Ajouter un nouveau produit"}</h2>
            </div>
            <div className="card-body">
              {isLoading && <div className="loader-overlay"><div className="loader"></div></div>}
              
              <form onSubmit={(e) => { e.preventDefault(); handleCreate(); }}>
                <div className="form-grid">
                  <div className="form-group">
                    <label htmlFor="productName">Nom du produit</label>
                    <input
                      id="productName"
                      type="text"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      placeholder="Ex: Table rectangulaire"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="productStock">Stock disponible</label>
                    <input
                      id="productStock"
                      type="number"
                      value={stock}
                      onChange={e => setStock(parseInt(e.target.value))}
                      min="0"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="productPrice">Prix journalier (€)</label>
                    <input
                      id="productPrice"
                      type="number"
                      value={dailyPrice}
                      onChange={e => setDailyPrice(parseFloat(e.target.value))}
                      min="0"
                      step="0.01"
                      required
                    />
                  </div>
                </div>

                <div className="categories-section">
                  <h3>Catégories</h3>
                  <div className="categories-container">
                    {categoryPaths.map((path, pathIndex) => (
                      <div key={pathIndex} className="category-path">
                        <div className="path-header">
                          <h4>Chemin de catégorie {pathIndex + 1}</h4>
                          {categoryPaths.length > 1 && (
                            <button 
                              type="button"
                              className="btn-icon delete"
                              onClick={() => removePath(pathIndex)}
                              title="Supprimer ce chemin"
                            >
                              ×
                            </button>
                          )}
                        </div>
                        <div className="path-levels">
                          {path.map((level, levelIndex) => (
                            <div key={levelIndex} className="level-input">
                              <input
                                type="text"
                                value={level}
                                onChange={e => handlePathChange(pathIndex, levelIndex, e.target.value)}
                                placeholder={`Niveau ${levelIndex + 1}`}
                              />
                              {levelIndex > 0 && (
                                <button 
                                  type="button"
                                  className="btn-icon remove"
                                  onClick={() => removeLevel(pathIndex, levelIndex)}
                                >
                                  ×
                                </button>
                              )}
                            </div>
                          ))}
                          <button 
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => addLevelToPath(pathIndex)}
                          >
                            + Ajouter un niveau
                          </button>
                        </div>
                      </div>
                    ))}
                    <button 
                      type="button"
                      className="btn btn-primary"
                      onClick={addNewPath}
                    >
                      + Nouvelle catégorie
                    </button>
                  </div>
                </div>

                <div className="photos-section">
                  <h3>Photos du produit</h3>
                  <div className="photos-upload">
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handlePhotoChange}
                      className="file-input"
                    />
                    <small className="help-text">
                      Formats acceptés: JPG, PNG, GIF. Taille max: 5MB par image
                    </small>
                  </div>

                  {photoPreviews.length > 0 && (
                    <div className="photos-preview">
                      <h4>Nouvelles photos</h4>
                      <div className="photos-grid">
                        {photoPreviews.map((src, index) => (
                          <div key={index} className="photo-item">
                            <img src={src} alt={`Aperçu ${index + 1}`} />
                            <button
                              type="button"
                              className="btn-icon delete"
                              onClick={() => handleRemoveSelectedPhoto(index)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {editingProduct?.photos?.length > 0 && (
                    <div className="current-photos">
                      <h4>Photos actuelles</h4>
                      <div className="photos-grid">
                        {editingProduct.photos.map(photo => (
                          <div key={photo.id} className="photo-item">
                            <img 
                              src={`https://backend.ouest-pro-event.fr${photo.url}`}
                              alt="Photo produit"
                            />
                            <button
                              type="button"
                              className="btn-icon delete"
                              onClick={() => handleDeletePhoto(photo.id)}
                            >
                              ×
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button type="submit" className="btn btn-success">
                    {editingProduct ? "Enregistrer les modifications" : "Créer le produit"}
                  </button>
                  {editingProduct && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditingProduct(null);
                        setName('');
                        setStock(0);
                        setDailyPrice(0);
                        setCategoryPaths([[""]]);
                        setSelectedPhotos([]);
                        setPhotoPreviews([]);
                      }}
                    >
                      Annuler
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>

        <section className="products-list-section">
          <div className="card">
            <div className="card-header">
              <h2>Liste des produits</h2>
            </div>
            <div className="card-body">
              <div className="products-grid">
                {products.map(product => (
                  <div key={product.id} className="product-card">
                    <div className="product-header">
                      <h3>{product.name}</h3>
                      <div className="product-actions">
                        <button
                          className="btn btn-edit"
                          onClick={() => handleEdit(product)}
                        >
                          Modifier
                        </button>
                        <button
                          className="btn btn-delete"
                          onClick={() => handleDelete(product.id)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                    
                    <div className="product-details">
                      <div className="product-info">
                        <span className="info-label">Stock:</span>
                        <span className="info-value">{product.stock}</span>
                      </div>
                      <div className="product-info">
                        <span className="info-label">Prix journalier:</span>
                        <span className="info-value">{product.dailyPrice} €</span>
                      </div>
                    </div>

                    {product.categoryPaths?.length > 0 && (
                      <div className="product-categories">
                        <h4>Catégories</h4>
                        <ul>
                          {product.categoryPaths.map((path, idx) => (
                            <li key={idx}>{path.join(" > ")}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {product.photos?.length > 0 && (
                      <div className="product-photos">
                        <div className="photos-grid">
                          {product.photos.map(photo => (
                            <img
                              key={photo.id}
                              src={`https://backend.ouest-pro-event.fr${photo.url}`}
                              alt={`Photo de ${product.name}`}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
 


  );
}

export default ProductsPage;
