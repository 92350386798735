// NavBar.js

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function NavBar({ onLogout }) { // Recevoir onLogout comme prop
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/home" className="logo">
          Ouest Pro Event
        </Link>
        <button 
          className={`navbar-burger ${isOpen ? 'is-active' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>

      <div className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
        <div className="navbar-links">
          <Link 
            to="/home" 
            className={`nav-link ${isActive('/home') ? 'active' : ''}`}
          >
            Accueil
          </Link>
          <Link 
            to="/products" 
            className={`nav-link ${isActive('/products') ? 'active' : ''}`}
          >
            Produits
          </Link>
          <Link 
            to="/rentals" 
            className={`nav-link ${isActive('/rentals') ? 'active' : ''}`}
          >
            Locations
          </Link>
          {/* <Link 
            to="/register" 
            className={`nav-link ${isActive('/register') ? 'active' : ''}`}
          >
            Créer un compte
          </Link> */}
        </div>
        <div className="navbar-logout">
          <button onClick={onLogout} className="logout-button">
            Déconnexion
          </button>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
